const pascalCaseToHyphenated = (string: string) => {
    if (!string) {
        return "";
    }

    return (
        string
            // Replace PascalCase with hyphen-separated words
            .replace(/\.?([A-Z])/g, function (_, y) {
                return "-" + y.toLowerCase();
            })
            // remove prepended hyphen
            .replace(/^-/, "")
    );
};

export default pascalCaseToHyphenated;
