import React from "react";

import { ChatBubbleQuestionIcon, GroupIcon, HomeIcon, MultiplePagesIcon } from "@volum/hiero";
import StandardNavBar from "../StandardNavBar";

const LINKS = [
    { to: "/", label: "Accueil", icon: HomeIcon },
    { to: "/bills", label: "Factures", icon: MultiplePagesIcon },
    { to: "/tickets", label: "Tickets", icon: ChatBubbleQuestionIcon },
    { to: "/team", label: "Équipe", icon: GroupIcon },
];

const RentersNavBar = () => <StandardNavBar links={LINKS} />;

export default RentersNavBar;
