import { useState, useCallback, Dispatch, SetStateAction } from "react";

type useBooleanMethodsType = {
    set: Dispatch<SetStateAction<boolean>>;
    setTrue: () => void;
    setFalse: () => void;
    toggle: () => void;
};
type useBooleanReturnType = [boolean, useBooleanMethodsType];

/*
 ** @dev: this hook make easier the boolean value
 ** manipulation. Everytime you wanna deal with
 ** an useState which will manipulate boolean value
 ** use this hook to have access to some useful methods
 ** like the toglle, setFalse, setTrue functions.
 ** Your components will use a less verbose interface
 ** to update your boolean values.
 */
const useBoolean = (initialValue: boolean): useBooleanReturnType => {
    const [value, set] = useState<boolean>(initialValue);
    const setTrue = useCallback(() => set(true), []);
    const setFalse = useCallback(() => set(false), []);
    const toggle = useCallback(() => set((val) => !val), []);

    return [value, { set, setTrue, setFalse, toggle }];
};

export default useBoolean;
