import { lazy } from "react";
import { RouteType } from "./router";

const LoginRoute = lazy(() => import("src/routes/shared/login/login-route"));
const Logout = lazy(() => import("src/routes/shared/Logout"));
const PasswordChangeRequest = lazy(() => import("src/routes/shared/PasswordChangeRequest"));
const PasswordChange = lazy(() => import("src/routes/shared/PasswordChange"));
const LeasePriceContainer = lazy(() => import("src/routes/shared/lease-price/lease-price-container"));
const WorkspacePrice = lazy(() => import("src/routes/shared/WorkspacePrice"));
const OfficeMapperRoute = lazy(() => import("src/routes/shared/office-mapper/office-mapper-route"));
const ExpiredSharedPropositionPage = lazy(
    () => import("src/routes/shared/ExpiredSharedProposition/ExpiredSharedPropositionPage")
);
const ExpiredInvitationView = lazy(() => import("src/routes/shared/ExpiredInvitation/"));
const Proposition = lazy(() => import("src/routes/shared/Proposition/"));
const AcceptInvite = lazy(() => import("src/routes/shared/AcceptInvite/"));

const StripeConnectAccountLinkRedirect = lazy(() => import("src/routes/shared/StripeConnectAccountLinkRedirect"));
const StripeConnectAccountLinkExpired = lazy(() => import("src/routes/shared/StripeConnectAccountLinkExpired"));

/**
 * Routes specific to every types of users >>logged-in or not<<.
 */
const sharedRoutes: Array<RouteType> = [
    { path: "/login", component: LoginRoute },
    { path: "/logout", component: Logout },
    { path: "/password-request", component: PasswordChangeRequest },
    { path: "/password-change", component: PasswordChange },
    { path: "/invites/expired", component: ExpiredInvitationView },
    { path: "/invites/:inviteId", component: AcceptInvite },

    { path: "/tools/workplace-price/:token", component: WorkspacePrice },
    { path: "/tools/workplace-price", component: WorkspacePrice },
    { path: "/tools/officemapper", component: OfficeMapperRoute },
    { path: "/tools/lease-price", component: LeasePriceContainer },

    { path: "/shared/expired", component: ExpiredSharedPropositionPage },
    { path: "/shared/:token", component: Proposition },
    { path: "/offices/:id", component: Proposition },

    { path: "/stripe/connect-expired/:token", component: StripeConnectAccountLinkExpired },
    { path: "/stripe/connect-redirection", component: StripeConnectAccountLinkRedirect },
];

export default sharedRoutes;
