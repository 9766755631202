import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./GuestNavBar.module.css";

import VolumLogo from "src/img/shared/logos/logo-white.svg";
import { CancelIcon, MenuIcon } from "@volum/hiero";

const GuestNavBar = () => {
    const [mobileNavOpen, setMobileNavOpen] = useState(false);

    return (
        <header>
            <nav className={styles.topBar} role="navigation">
                <div>
                    <a href="https://volum.co/">
                        <img className={styles.logo} alt="volum" src={VolumLogo} />
                    </a>
                </div>
                <div className={styles.mobileMenuButton}>
                    {mobileNavOpen ? (
                        <CancelIcon onClick={() => setMobileNavOpen(false)} />
                    ) : (
                        <MenuIcon onClick={() => setMobileNavOpen(true)} />
                    )}
                </div>
                <div className={`${styles.links} ${mobileNavOpen ? styles.mobileLinksOpened : ""}`}>
                    <div>
                        <a className={styles.link} href="https://volum.co/">
                            Retourner sur volum.co
                        </a>
                    </div>
                    <div>
                        <Link className={styles.link} to="/login" onClick={() => setMobileNavOpen(false)}>
                            Connexion
                        </Link>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default GuestNavBar;
