import styles from "./VisitorCTA.module.css";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import Button from "src/components/Buttons/Button";
import useAppUser from "src/hooks/app-user";
import { LeaveVisitorModeMutation, LeaveVisitorModeMutationVariables } from "./__generated__/VisitorCTA";

const LEAVE_VISITOR_MODE = gql`
    mutation LeaveVisitorMode {
        leave_visitor_mode
    }
`;

const VisitorCTA = () => {
    const { user } = useAppUser();
    const [leaveVisitorModeMutation] = useMutation<LeaveVisitorModeMutation, LeaveVisitorModeMutationVariables>(
        LEAVE_VISITOR_MODE
    );

    const handleLeaveVisitorMode = async () => {
        const response = await leaveVisitorModeMutation();
        if (response.data?.leave_visitor_mode) {
            window.location.assign(`/managed/teams/${user?.user_type.toLowerCase()}s`);
        } else {
            console.error(response);
        }
    };

    return (
        <div className={styles.visitorCTA}>
            <p className={styles.text}>Vous êtes en mode visiteur</p>
            <Button onClick={handleLeaveVisitorMode} color="primary" size="small">
                Quitter
            </Button>
        </div>
    );
};

export default VisitorCTA;
