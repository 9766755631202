import { lazy } from "react";
import { RouteType } from "./router";

const Home = lazy(() => import("src/routes/owners/Home/"));
const SpaceList = lazy(() => import("src/routes/owners/Spaces/SpacesView"));
const Space = lazy(() => import("src/routes/owners/Space/"));
const OwnersProfile = lazy(() => import("src/routes/owners/Profile"));
const TeamView = lazy(() => import("src/routes/owners/TeamView"));
const ContractsList = lazy(() => import("src/routes/owners/contracts/ContractsList"));
const Contract = lazy(() => import("src/routes/owners/contracts/Contract"));
const SimulationsList = lazy(() => import("src/routes/owners/simulations/SimulationsList"));
const SimulationCreation = lazy(() => import("src/routes/owners/simulations/SimulationCreation"));
const SimulationResult = lazy(() => import("src/routes/owners/simulations/Simulation"));

/**
 * Routes specific to logged-in owners.
 */
const ownersRoutes: Array<RouteType> = [
    { path: "/", exact: true, component: Home },
    { path: "/simulations/new", component: SimulationCreation },
    { path: "/simulations/:simulationId", component: SimulationResult },
    { path: "/simulations", component: SimulationsList },
    { path: "/contracts/:contractId", component: Contract },
    { path: "/contracts", component: ContractsList },
    { path: "/profile", component: OwnersProfile },
    { path: "/team", component: TeamView },
    { path: "/spaces/:spaceId", component: Space },
    { path: "/spaces", component: SpaceList },
].map((route) => ({ ...route, private: true }));

export default ownersRoutes;
