import { refreshAccessToken, removeAuthCookies } from "src/auth";
import { ServerError } from "@apollo/client";
import promiseToObservable from "./promiseToObservable";
import { authToken } from "src/http-config";
import axios from "src/services/axios";
import { createAppNotification } from "src/hooks/app-notification";

export const restErrorHandler = async (error: any): Promise<any> => {
    const originalRequest = error.config;
    const originalRequestUrl: string = error.config.url;
    switch (error?.response?.status) {
        default: {
            createAppNotification("Une erreur est survenue.");
            Promise.reject(error);
            return;
        }
        case 400:
            // To prevent exceptions when it's only wrong login infos
            if (originalRequestUrl.includes("/users/obtain-access/")) {
                Promise.reject();
            } else {
                createAppNotification("Une erreur est survenue.");
                Promise.reject(error);
            }
            return;
        case 401: {
            // Prevent infinite loops
            if (originalRequestUrl.includes("/users/refresh-access")) {
                removeAuthCookies();
                window.location.assign("/login");
            }

            if (error.response.data.code === "token_not_valid") {
                const refreshed = await refreshAccessToken();
                console.log("refreshed", refreshed);
                if (refreshed) {
                    originalRequest.headers["Authorization"] = "Bearer " + authToken;
                    return axios(originalRequest);
                } else {
                    removeAuthCookies();
                    window.location.assign("/login");
                }
            }
            Promise.reject(error);
            return;
        }
        /* too many request */
        case 429: {
            createAppNotification("Trop de requêtes successives, veuillez réessayer ultérieurement.");
            Promise.reject();
        }
    }
};

/**
 * @param error The server error sent by ApolloGraphql
 * Returns if the request should be re-sent.
 */
export const gqlErrorHandler = (error: ServerError, forward: any, operation: any): any | undefined => {
    switch (error.statusCode) {
        default: {
            createAppNotification("Une erreur est survenue.");
            return;
        }
        case 401: {
            if (error?.result?.code === "token_not_valid") {
                return promiseToObservable(refreshAccessToken()).flatMap((refreshed) => {
                    if (refreshed) {
                        operation.setContext(({ headers = {} }) => ({
                            headers: {
                                ...headers,
                                authorization: `Bearer ${authToken}` || null,
                            },
                        }));
                        return forward(operation);
                    } else {
                        removeAuthCookies();
                        window.location.assign("/login");

                        throw Error("Cannot refresh access token");
                    }
                });
            }
            return;
        }
    }
};
