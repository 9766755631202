import environment from "src/utils/environment";

export const GTAG_TRACKING_ID = "UA-70781610-6";

export const pageview = (url: string): void => {
    if (environment !== "PROD") return;
    if (window.gtag === undefined) return;

    window.gtag("config", GTAG_TRACKING_ID, { page_path: url });
};

/** Do not use directly, please implement a function in events.ts */
export const _gaEvent = (action: string, category: string, label: string, value?: number): void => {
    if (environment !== "PROD") return;
    if (window.gtag === undefined) return;

    let event: { [key in string]: string | number } = {
        event_category: category,
        event_label: label,
    };

    if (value !== null && value !== undefined) event["value"] = value;

    window.gtag("event", action, event);
};
