import { BuildingIcon, GroupIcon, HomeIcon, JournalIcon, PageStarIcon } from "@volum/hiero";
import StandardNavBar from "../StandardNavBar";

const LINKS = [
    { to: "/", label: "Accueil", icon: HomeIcon },
    { to: "/spaces", label: "Actifs", icon: BuildingIcon },
    { to: "/simulations", label: "Simulations", icon: JournalIcon },
    { to: "/contracts", label: "Contrats", icon: PageStarIcon },
    { to: "/team", label: "Équipe", icon: GroupIcon },
];

const OwnersNavBar = () => <StandardNavBar links={LINKS} />;

export default OwnersNavBar;
