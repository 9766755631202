import { useEffect } from "react";
import { useLocation } from "react-router";
import * as gtag from "src/libs/gtag";

/*
 * This hook trigger google-analytics pageview event.
 */
const useGtagPageView = (): void => {
    const location = useLocation();

    useEffect(() => {
        gtag.pageview(location.pathname);
    }, [location]);
};

export default useGtagPageView;
