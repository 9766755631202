import Cookies from "js-cookie";

import { setLoggedHeaders } from "./http-config";

import { refreshAuthToken, getUser } from "src/services/";
import AppUser from "src/models/users/app-user";

/**
 * Checks if a token is expired
 */
const tokenIsExpired = (token: string): boolean => {
    if (!token) return true;
    const tokenParts = JSON.parse(atob(token.split(".")[1]));

    // Expiration date in token is expressed in seconds, while now() returns milliseconds
    const now = Math.ceil(Date.now() / 1000);
    return tokenParts.exp <= now;
};

/**
 * Returns the user_id value contained in the token
 */
export const getUIDFromToken = (token: string): string => {
    const tokenParts = JSON.parse(atob(token.split(".")[1]));
    return tokenParts.user_id;
};

/**
 * Used at page load
 * Checks if a user is stored, and if its refresh token is still valid
 */
export const checkStoredUser = async () => {
    const refreshed = await refreshAccessToken();
    if (refreshed) return await getAppUser();
    else return null;
};

/**
 * Refreshed the auth token of the user.
 * If the refresh token is not valid refirects the user to the login
 * @param refreshToken The user's refresh token
 * Returns if the token has been refreshed
 */
export const refreshAccessToken = async () => {
    const refreshToken = Cookies.get("rt");
    if (!refreshToken || tokenIsExpired(refreshToken)) {
        return false;
    }

    try {
        const response: any = await refreshAuthToken(refreshToken);
        let data = response.data;
        Cookies.set("at", data.access, { path: "/" });
        Cookies.set("rt", data.refresh, { path: "/" });
        setLoggedHeaders(data.access);
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
};

/**
 * Get the app user.
 */
export const getAppUser = async () => {
    const refreshToken = Cookies.get("rt");
    if (!refreshToken) return null;

    return await getUser(getUIDFromToken(refreshToken))
        .then((response: any) => {
            if (response.data.response === "success") {
                let user = new AppUser(response.data.data);
                return user;
            } else {
                console.error(response.data.error);
                return null;
            }
        })
        .catch((error) => {
            console.error(error);
            return null;
        });
};

/**
 * Sets the cookies with the JWT informations
 * @param accessToken The users's access token
 * @param refreshToken  The users's refresh token
 */
export const setAuthCookies = (accessToken: string, refreshToken: string) => {
    Cookies.set("at", accessToken, { path: "/" });
    Cookies.set("rt", refreshToken, { path: "/" });
};

/**
 * Removes JWT informations from the cookies
 */
export const removeAuthCookies = () => {
    Cookies.remove("at", { path: "/" });
    Cookies.remove("rt", { path: "/" });
};
