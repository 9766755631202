import MainRouter from "src/router/";
import AppProvider from "src/Providers";

import "./css/global/_normalize.css";
import "./css/stylesheet.css";
import "./css/global/_color-classes.css";
import "./css/global/_globals.css";
import "./css/global/_text-classes.css";

import initSentry from "./libs/sentry";

initSentry();

// trigger deploy

const App = () => {
    return (
        <AppProvider>
            <MainRouter />
        </AppProvider>
    );
};

export default App;
