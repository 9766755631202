class AgentOptions {
    id: number;
    slack_handle: string;
    booking_link: string;

    constructor(props: any) {
        if (props) {
            this.id = props["id"];
            this.slack_handle = props["slack_handle"];
            this.booking_link = props["booking_link"];
        }
    }
}

export default AgentOptions;
