import { lazy } from "react";
import { RouteType } from "./router";

const SubscribeContainer = lazy(() => import("src/routes/shared/subscribe/subscribe-container"));
const PostBrokerConfirmation = lazy(() => import("src/routes/shared/subscribe/PostBrokerConfirmation"));
const VerifyEmailContainer = lazy(() => import("src/routes/shared/verify-email/verify-email-container"));

const SimulationResult = lazy(() => import("src/routes/owners/simulations/Simulation"));
const SimulationCreation = lazy(() => import("src/routes/owners/simulations/SimulationCreation"));

/**
 * These routes are only exposed to users that are not logged-in.
 */
const anonRoutes: Array<RouteType> = [
    { path: "/verify", component: VerifyEmailContainer },
    { path: "/subscribe/welcome-broker-validation", component: PostBrokerConfirmation },
    { path: "/subscribe/broker", component: SubscribeContainer },
    { path: "/simulations/new", component: SimulationCreation },
    { path: "/simulations/:simulationId", component: SimulationResult },
];

export default anonRoutes;
