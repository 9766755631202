import React, { MouseEvent, ReactElement } from "react";
import styles from "./Button.module.css";

const ButtonLoader = () => (
    <svg
        className={styles.loader}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round">
        <line x1="12" y1="2" x2="12" y2="6"></line>
        <line x1="12" y1="18" x2="12" y2="22"></line>
        <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
        <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
        <line x1="2" y1="12" x2="6" y2="12"></line>
        <line x1="18" y1="12" x2="22" y2="12"></line>
        <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
        <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
    </svg>
);

type ButtonProps = {
    className?: string;
    loading?: boolean;
    submit?: boolean;
    onClick?(e: MouseEvent<HTMLButtonElement>): void;
    disabled?: boolean;
    color?: "default" | "primary" | "secondary" | "tertiary-blue" | "tertiary-pink" | "dark-green";
    size?: "default" | "tiny" | "small" | "big";
    variant?: "default" | "outline" | "floating";
    fullWidth?: boolean;
    children?: React.ReactNode;
    iconLeft?: ReactElement;
    iconRight?: ReactElement;
};

const Button = (props: ButtonProps) => {
    const onClick = (e: MouseEvent<HTMLButtonElement>) => {
        if (props.loading) return;
        if (props.onClick !== undefined) props.onClick(e);
    };

    const variant = props.variant ? styles[`variant_${props.variant}`] : undefined;
    const color = props.color ? styles[`color_${props.color}`] : undefined;
    const size = props.size ? styles[`size_${props.size}`] : undefined;
    const fullWidth = props.fullWidth ? styles.fullWidth : undefined;

    const className = [props.className, styles.button, variant, color, size, fullWidth]
        .filter((property) => property !== undefined && property !== "default")
        .join(" ");

    return (
        <button
            className={className}
            type={props.submit ? "submit" : "button"}
            disabled={props.disabled}
            onClick={onClick}>
            <div className={props.loading ? styles.contentHidden : styles.content}>
                {props.iconLeft &&
                    React.cloneElement(props.iconLeft, {
                        className: `${props.iconLeft.props.className} ${styles.icon}`,
                    })}
                <div>{props.children}</div>
                {props.iconRight &&
                    React.cloneElement(props.iconRight, {
                        className: `${props.iconRight.props.className} ${styles.icon}`,
                    })}
            </div>
            {props.loading && (
                <div className={styles.loaderContainer}>
                    <ButtonLoader />
                </div>
            )}
        </button>
    );
};

export default Button;
