import { lazy } from "react";
import { RouteType } from "./router";

const ProfileView = lazy(() => import("src/routes/brokers/ProfileView"));
const AnnounceView = lazy(() => import("src/routes/brokers/AnnounceView"));
const AnnouncesView = lazy(() => import("src/routes/brokers/AnnouncesView"));

/**
 * Routes specific to logged-in brokers.
 */
const brokersRoutes: Array<RouteType> = [
    { path: "/", exact: true, component: AnnouncesView },
    { path: "/profile", component: ProfileView },
    { path: "/announces/:id", component: AnnounceView },
    { path: "/announces", component: AnnouncesView },
].map((route) => ({ ...route, private: true }));

export default brokersRoutes;
