import { atom } from "recoil";
import AppUser from "./models/users/app-user";

export const countersState = atom({
    key: "countersState",
    default: {
        awaitingDemands: 0,
        awaitingTickets: 0,
    },
});

export const appUserState = atom<AppUser | undefined>({
    key: "appUserState",
    default: undefined,
});
