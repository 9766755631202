export enum EventTypes {
    AVAILABLE_DEMANDS_CHANGED = "AVAILABLE_DEMANDS_CHANGED",
    CONTRACT_UPDATED = "CONTRACT_UPDATED",
    SIMULATION_FILES_GENERATED = "SIMULATION_FILES_GENERATED",
    VISITOR_MODE_SWITCHED = "VISITOR_MODE_SWITCHED",
}

export type AvailableDemandsChanged = {};
export type ContractUpdated = {
    id: string;
};
export type SimulationFilesGenerated = {
    id: string;
};
export type VisitorModeSwitched = {};
