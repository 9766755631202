import { Operation } from "@apollo/client";
import environment from "src/utils/environment";
import pascalCaseToHyphenated from "src/utils/pascalCaseToHyphenated";

const DEV_API = "http://127.0.0.1:8888";
const STAGING_API = "https://api.preprod.volum.co";
const PROD_API = "https://api.volum.co";
const GRAPHQL_QUERY_PATH_PREFIX = "graphql/";

export function getApiEndpoint(environment: string) {
    switch (environment) {
        default: {
            return DEV_API;
        }
        case "STAGING": {
            return STAGING_API;
        }
        case "PROD": {
            return PROD_API;
        }
    }
}

export const API_ENDPOINT = getApiEndpoint(environment);

export const BASIC_HEADERS = { "Content-Type": "application/json", Accept: "application/json" };
export let LOGGED_HEADERS = { "Content-Type": "application/json", Accept: "application/json", Authorization: "" };
export let authToken = "";

export function setLoggedHeaders(accessToken: string) {
    authToken = accessToken;
    LOGGED_HEADERS.Authorization = `Bearer ${accessToken}`;
}

const getGraphQLQueryPath = (environment: string, operation: Operation) => {
    const formattedOperationName = pascalCaseToHyphenated(operation.operationName);
    return `${GRAPHQL_QUERY_PATH_PREFIX}${formattedOperationName}`;
};

export function getGraphQLEndpoint(environment: string, operation: Operation) {
    const queryPath = getGraphQLQueryPath(environment, operation);

    switch (environment) {
        default: {
            return `${DEV_API}/${queryPath}`;
        }
        case "STAGING": {
            return `${STAGING_API}/${queryPath}`;
        }
        case "PROD": {
            return `${PROD_API}/${queryPath}`;
        }
    }
}
