import { lazy } from "react";
import { RouteType } from "./router";

const RenterProfilPage = lazy(() => import("src/routes/renters/RenterProfil/RenterProfilPage"));
const RentersTeamPage = lazy(() => import("src/routes/renters/RenterTeam/RentersTeamPage"));
const ContractRoute = lazy(() => import("src/routes/renters/Contract"));
const BillsRoute = lazy(() => import("src/routes/renters/Bills"));
const TicketsRoute = lazy(() => import("src/routes/renters/Tickets"));
const TicketCreation = lazy(() => import("src/routes/renters/TicketCreation"));
const LegalRoute = lazy(() => import("src/routes/renters/Legal"));

/**
 * Routes specific to logged-in renters.
 */
const rentersRoutes: Array<RouteType> = [
    { path: "/", exact: true, component: ContractRoute },
    { path: "/profile", component: RenterProfilPage },
    { path: "/team", component: RentersTeamPage },
    { path: "/bills", component: BillsRoute },
    { path: "/tickets/add", component: TicketCreation },
    { path: "/tickets", component: TicketsRoute },
    { path: "/legal", component: LegalRoute },
].map((route) => ({ ...route, private: true }));

export default rentersRoutes;
