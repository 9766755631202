import React from "react";
import styles from "./Toast.module.css";
import { ToastContainer, Slide } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const Toast = () => {
    return (
        <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
            transition={Slide}
            bodyClassName={styles.toastBody}
            progressClassName={styles.toastProgress}
        />
    );
};

export default Toast;
