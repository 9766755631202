import AgentOptions from "./agent-options";

export enum UserType {
    OWNER = "OWNER",
    RENTER = "RENTER",
    AGENT = "AGENT",
    BROKER = "BROKER",
}

class Team {
    id: string;
    name: string;
    slug: string;
    team_type: UserType;
    owner: boolean;
    agent: boolean;

    constructor(props: any) {
        if (props) {
            this.id = props["id"];
            this.name = props["name"];
            this.slug = props["slug"];
            this.team_type = props["team_type"];
        }
    }

    isAgent = () => {
        return this.team_type === UserType.AGENT;
    };

    isRenter = () => {
        return this.team_type === UserType.RENTER;
    };

    isOwner = () => {
        return this.team_type === UserType.OWNER;
    };

    isBroker = () => {
        return this.team_type === UserType.BROKER;
    };
}

class AppUser {
    id: string;
    email: string;
    first_name: string;
    last_name: string;
    phone: string;

    user_type: UserType;
    is_superuser: boolean;

    real_time_id: string;
    origin: string;
    locale: string;

    agent_profil: AgentOptions;
    team: Team;

    constructor(props: any) {
        if (props) {
            this.id = props["id"].toString();
            this.email = props["email"];
            this.first_name = props["first_name"];
            this.last_name = props["last_name"];
            this.phone = props["phone"];
            this.team = props["team"];

            this.user_type = props["user_type"];
            this.real_time_id = props["real_time_id"];
            this.origin = props["origin"];
            this.locale = props["locale"];
            this.is_superuser = props["is_superuser"];

            this.agent_profil = new AgentOptions(props["agent_profil"]);
        }
    }

    isAgent = () => {
        return this.user_type === UserType.AGENT;
    };

    isRenter = () => {
        return this.user_type === UserType.RENTER;
    };

    isOwner = () => {
        return this.user_type === UserType.OWNER;
    };

    isBroker = () => {
        return this.user_type === UserType.BROKER;
    };

    isSuperUser = () => {
        return this.is_superuser;
    };
}

export default AppUser;
