import { toast } from "react-toastify";

function useAppNotification(): (text: string) => void {
    const setAppNotification = (text: string) => {
        toast(text);
    };

    return setAppNotification;
}

/** Prefer using useAppNotification hook in your React components.
 * This function must be only used when we need to dislay a notification
 * from outside of a React component.
 */
export function createAppNotification(text: string) {
    toast(text);
}

export default useAppNotification;
