import { API_ENDPOINT, LOGGED_HEADERS, BASIC_HEADERS } from "src/http-config";
import axios from "src/services/axios";

export function getLeasePrice(args: string) {
    return axios.get<any>(`${API_ENDPOINT}/tools/lease-price/${args}`, {
        headers: LOGGED_HEADERS,
    });
}

export function getEstimation(args: string) {
    return axios.get<any>(`${API_ENDPOINT}/tools/kelrue/?${args}`, {
        headers: BASIC_HEADERS,
    });
}

export function getEstimationEnrichData(args: string) {
    return axios.get<any>(`${API_ENDPOINT}/tools/enrich/${args}`, {
        headers: BASIC_HEADERS,
    });
}
