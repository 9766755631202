import { Observable } from "@apollo/client";

const promiseToObservable = (promise: any) =>
    new Observable((subscriber: any) => {
        promise.then(
            (value: any) => {
                if (subscriber.closed) return;
                subscriber.next(value);
                subscriber.complete();
            },
            (err: any) => subscriber.error(err)
        );
        return subscriber;
    });

export default promiseToObservable;
