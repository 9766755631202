import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { appUserState } from "src/appContext";
import { checkStoredUser } from "src/auth";
import useBoolean from "src/hooks/useBoolean";
import usePusher, { EventTypes, VisitorModeSwitched } from "src/hooks/usePusher";

export const useAuthSetup = () => {
    const setUser = useSetRecoilState(appUserState);
    const [authChecked, { setTrue: setAuthToTrue }] = useBoolean(false);

    useEffect(() => {
        checkStoredUser().then((user) => {
            if (user) setUser(user);
            setAuthToTrue();
        });
    }, []);

    return authChecked;
};

type AuthProviderProps = { readonly children: JSX.Element };

const AuthProvider = ({ children }: AuthProviderProps): JSX.Element | null => {
    const isUserSetup = useAuthSetup();

    usePusher<VisitorModeSwitched>(EventTypes.VISITOR_MODE_SWITCHED, () => {
        // Helps us prevent bug when agents have multiple tabs open and use visitor mode
        window.location.replace("/");
    });

    if (isUserSetup === false) return null;
    else return children;
};

export default AuthProvider;
