import axios from "axios";

import { restErrorHandler } from "src/utils/responseErrorHandler";

axios.interceptors.response.use(
    (response) => response,
    async (error) => await restErrorHandler(error)
);

export default axios;
