import { API_ENDPOINT, BASIC_HEADERS, LOGGED_HEADERS } from "src/http-config";
import axios from "src/services/axios";

export function checkEmailAvailability(email: string) {
    return axios.get<any>(API_ENDPOINT + "/users/check-email-availability/?email=" + email, { headers: BASIC_HEADERS });
}

export function getUser(id: string) {
    return axios.get<any>(API_ENDPOINT + "/users/" + id + "/", { headers: LOGGED_HEADERS });
}

export function subscribe(data: any) {
    const response = axios.post<any>(API_ENDPOINT + "/users/subscribe/", { data: data }, { headers: BASIC_HEADERS });
    return response;
}

export async function login(email: string, password: string) {
    const response = await axios.post<any>(API_ENDPOINT + "/users/obtain-access/", {
        username: email,
        password: password,
    });
    return response;
}

export function refreshAuthToken(refresh: string) {
    return axios.post<any>(
        API_ENDPOINT + "/users/refresh-access/",
        { refresh: refresh },
        { headers: { Authorization: "Bearer " + refresh } }
    );
}

export function saveUser(data: any) {
    return axios.post<any>(API_ENDPOINT + "/users/" + data.id + "/", { data: data }, { headers: LOGGED_HEADERS });
}

export function setUserType(id: number, type: string) {
    return axios.post<any>(
        API_ENDPOINT + "/users/" + id + "/set-profil-type/",
        { data: { type: type } },
        { headers: LOGGED_HEADERS }
    );
}

export function verifyEmail(email: string, token: string) {
    return axios.post<any>(
        API_ENDPOINT + "/users/verify-email/",
        { email: email, token: token },
        { headers: LOGGED_HEADERS }
    );
}

export function sendPasswordRequest(email: string) {
    return axios.post<any>(API_ENDPOINT + "/users/password-change-request/", {
        email: email,
    });
}

export function sendPasswordChange(email: string, password: string, key: string) {
    return axios.post<any>(API_ENDPOINT + "/users/change-password/", {
        email: email,
        password: password,
        key: key,
    });
}

export function setLocale(locale: string) {
    return axios.post<any>(API_ENDPOINT + "/users/set-locale/", { locale: locale }, { headers: LOGGED_HEADERS });
}

export function updateProfilPicture(userId: string, pictureFile: File) {
    const data = new FormData();
    data.append("file", pictureFile);
    return axios.post<any>(API_ENDPOINT + "/users/" + userId + "/profil-picture/", data, { headers: LOGGED_HEADERS });
}
