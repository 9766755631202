import { _gaEvent } from "./gtag";
import { _plausibleEvent } from "./plausible";

const events = {
    SIGN_IN: "signIn",
    SIGN_OUT: "signOut",
    DOWNLOAD: "download",
    ACTION: "action",
};

const categories = {
    ENGAGEMENT: "engagement",
};

export const sendLoginEvent = (userType: string, userId: number) => {
    _gaEvent(events.SIGN_IN, categories.ENGAGEMENT, userType, userId);
    _plausibleEvent(events.SIGN_IN, { userType, userId });
};

export const sendLogoutEvent = (userType: string, userId: number) => {
    _gaEvent(events.SIGN_OUT, categories.ENGAGEMENT, userType, userId);
    _plausibleEvent(events.SIGN_OUT, { userType, userId });
};

export const sendDownloadEvent = (userType: string, assetType: "simulation" | "bill" | "contract") => {
    _gaEvent(events.DOWNLOAD, categories.ENGAGEMENT, assetType);
    _plausibleEvent(events.DOWNLOAD, { userType, assetType });
};

export const sendActionEvent = (action: string, extra: { [key: string]: string | number } = {}) => {
    _gaEvent(events.ACTION, categories.ENGAGEMENT, action);
    _plausibleEvent(events.ACTION, { action, ...extra });
};
