import React, { lazy, Suspense } from "react";
import { Redirect, Route, BrowserRouter, Switch } from "react-router-dom";

import VisitorCTA from "src/components/agents/VisitorCTA";
import RentersNavBar from "src/components/Navbars/RentersNavBar";
import OwnersNavBar from "src/components/Navbars/OwnersNavBar";
import BrokerNavbar from "src/components/Navbars/BrokersNavBar";
import Toast from "src/components/Toast";
import useAppUser from "src/hooks/app-user";

import agentsRoutes from "./agents-routes";
import ownersRoutes from "./owners-routes";
import rentersRoutes from "./renters-routes";
import brokersRoutes from "./brokers-routes";
import sharedRoutes from "./shared-routes";
import anonRoutes from "./anons-routes";
import GtagProvider from "src/Providers/GtagProvider";
import GuestNavBar from "src/components/Navbars/GuestNavBar";

const AgentTopBarContainer = lazy(() => import("src/components/agents/agent-topbar/agent-topbar-container"));
const NotFound = lazy(() => import("src/routes/shared/not-found/not-found"));

export type RouteType = {
    path?: string;
    exact?: boolean;
    key?: string;
    redirect?: string;
    component?: any;
    private?: boolean;
};

const MainRouter = () => {
    const { user } = useAppUser();

    // The list of the routes which are gonna be exposed to the users
    let routes: Array<RouteType> = [];

    /** Two cases :
     *    - the user is connected: we only want to expose the routes depending on his user_type and
     *      the shared routes (like the tools for example)
     *
     *    - the user is not connected: we expose every routes (except the agents'), plus the specific anonRoutes.
     *      That way if the user is trying to access a private route without being logged-in he would be redirected
     *      to the login route, with the possiblity to be redirected after login (/login?next=/my/private/route/)
     */
    if (user !== undefined) {
        if (user.isAgent()) routes = routes.concat(agentsRoutes);
        if (user.isOwner()) routes = routes.concat(ownersRoutes);
        if (user.isRenter()) routes = routes.concat(rentersRoutes);
        if (user.isBroker()) routes = routes.concat(brokersRoutes);

        // When logged-in always set shared routes after user specific ones
        routes = routes.concat(sharedRoutes);
    } else {
        // When anon always set shared and anon routes before the user specific ones,
        // since we want these ones to be used, not the private user_type specific ones
        routes = routes.concat(sharedRoutes);
        routes = routes.concat(anonRoutes);

        // Be careful no to expose the agents routes to anonymous users.
        routes = routes.concat(ownersRoutes);
        routes = routes.concat(rentersRoutes);
        routes = routes.concat(brokersRoutes);
    }

    // We push the 404 routes only at the very end
    routes.push({ path: "/404", component: NotFound });
    routes.push({ component: NotFound });

    // Converts the list of RouteType routes to the react-router Routes while taking care of private routes
    const toReactRoutes = (routesList: Array<RouteType>) => {
        return routesList.map((route) => {
            // Handles redirect routes
            if (route.redirect)
                return (
                    <Redirect
                        key={route.key || `redi${route.path}${route.redirect}`}
                        from={route.path}
                        to={route.redirect}
                        exact={route.exact || false}
                    />
                );

            const key = route.key || route.path || "idk";
            return (
                <Route
                    key={key}
                    path={route.path}
                    exact={route.exact || false}
                    render={(props) => {
                        // If it's a private route and the user is not logged-in we want to redirect him.
                        if (user === undefined && route.private)
                            return (
                                <Redirect
                                    key={key}
                                    to={{
                                        pathname: "/login",
                                        search: "?next=" + props.location.pathname,
                                    }}
                                />
                            );
                        // Otherwise just return the component
                        return <route.component {...props} />;
                    }}
                />
            );
        });
    };

    return (
        <React.Fragment>
            <Suspense fallback={null}>
                <Toast />
                <BrowserRouter>
                    <GtagProvider />
                    {user?.agent_profil.id && !user.isAgent() && <VisitorCTA />}

                    {user === undefined && (
                        <React.Fragment>
                            <GuestNavBar />
                            <Switch>{toReactRoutes(routes)}</Switch>
                        </React.Fragment>
                    )}

                    {user !== undefined && user.isRenter() && (
                        <>
                            <RentersNavBar />
                            <Switch>{toReactRoutes(routes)}</Switch>
                        </>
                    )}

                    {user !== undefined && user.isOwner() && (
                        <>
                            <OwnersNavBar />
                            <Switch>{toReactRoutes(routes)}</Switch>
                        </>
                    )}

                    {user !== undefined && user.isAgent() && (
                        <React.Fragment>
                            <AgentTopBarContainer />
                            <div className="page-container">
                                <Switch>{toReactRoutes(routes)}</Switch>
                            </div>
                        </React.Fragment>
                    )}

                    {user !== undefined && user.isBroker() && (
                        <>
                            <BrokerNavbar />
                            <Switch>{toReactRoutes(routes)}</Switch>
                        </>
                    )}
                </BrowserRouter>
            </Suspense>
        </React.Fragment>
    );
};

export default MainRouter;
