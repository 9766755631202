import { useEffect } from "react";
import { getChannel } from "src/libs/pusher";
import useAppUser from "../app-user";
import { EventTypes } from "./types";

function usePusher<T>(eventType: EventTypes, callback: (data: T) => void) {
    const { user } = useAppUser();

    useEffect(() => {
        if (user?.real_time_id) {
            // Sub the the users private channel
            const channel = getChannel(user?.real_time_id);

            // Listen to specific event on the channel
            channel.bind(eventType, callback);

            // Unbind once the unmount the components
            return () => {
                channel.unbind(eventType);
            };
        }
    }, [user?.real_time_id]);
}

export default usePusher;
