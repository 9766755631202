import { useHistory } from "react-router-dom";

import AppUser from "src/models/users/app-user";
import { removeAuthCookies } from "src/auth";
import { useRecoilState } from "recoil";
import { appUserState } from "src/appContext";
import { sendLogoutEvent } from "src/libs/events";

type AppUserHook = {
    user?: AppUser;
    setUser: (updatedUser: AppUser) => void;
    logOutUser: () => void;
};

const useAppUser = (): AppUserHook => {
    const [user, setAppUser] = useRecoilState(appUserState);

    const history = useHistory();

    const setUser = (updatedUser: AppUser) => {
        setAppUser(updatedUser);
    };

    const logOutUser = () => {
        if (user) sendLogoutEvent(user.user_type, parseInt(user.id));
        setAppUser(undefined);
        removeAuthCookies();
        history.replace("/login");
    };

    return { user, setUser, logOutUser };
};

export default useAppUser;
