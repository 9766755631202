import Pusher from "pusher-js";

export var pusher = new Pusher("a24767c593cccdd7efe4", {
    cluster: "eu",
    encrypted: true,
});

// FIXME: find the right type
let userChannel: any | null = null;

export const getChannel = (realTimeId: string): any | null => {
    if (userChannel == null && realTimeId) {
        userChannel = pusher.subscribe(realTimeId);
    }
    return userChannel;
};
