import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import environment from "src/utils/environment";

const initSentry = (): void => {
    if (environment === "PROD" || environment === "PREPROD" || environment === "STAGING")
        Sentry.init({
            dsn: "https://737202017fcc44ebae1e7f1d3ffdd0f3@o51905.ingest.sentry.io/6208900",
            integrations: [new Integrations.BrowserTracing()],
            environment: process.env.NEXT_PUBLIC_REMOTE_ENV,
            tracesSampleRate: 0.2,
        });
};

export default initSentry;
