import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { InMemoryCache } from "@apollo/client/cache";
import { ApolloProvider as Provider, ApolloClient, ServerError } from "@apollo/client";

import { createUploadLink } from "apollo-upload-client";

import { getGraphQLEndpoint, authToken } from "src/http-config";
import { gqlErrorHandler } from "src/utils/responseErrorHandler";
import environment from "src/utils/environment";

const uploadLink = createUploadLink({
    uri: (operation) => {
        const graphQLEndpoint = getGraphQLEndpoint(environment, operation);
        return graphQLEndpoint;
    },
});

const errorLink = onError(({ operation, forward, graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
            console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        );
    if (networkError) {
        const error = networkError as ServerError;
        try {
            const forwardedOperation = gqlErrorHandler(error, forward, operation);
            if (forwardedOperation) return forwardedOperation;
        } catch (error) {
            console.error(error);
        }
    }
});

const authLink = setContext((_, { headers }) => {
    const token = authToken;
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        },
    };
});

const cache = new InMemoryCache();

export const apolloClient = new ApolloClient({
    link: authLink.concat(errorLink).concat(uploadLink),
    cache: cache,
    connectToDevTools: process.env.NODE_ENV === "development",
});

type ApolloProviderType = { readonly children: JSX.Element };

const ApolloProvider = ({ children }: ApolloProviderType): JSX.Element => {
    return <Provider client={apolloClient}>{children}</Provider>;
};

export default ApolloProvider;
