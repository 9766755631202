import React, { useState } from "react";

import VolumLogo from "src/img/shared/logos/logo-white.svg";

import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import styles from "./StandardNavBar.module.css";
import { AlignRightIcon, CancelIcon, UserIcon } from "@volum/hiero";

export type LinkType = {
    to?: string;
    label: string;
    icon: any;
    children?: Array<LinkType>;
};

const MobileLink = ({
    link,
    setModileNavBarOpen,
}: {
    link: LinkType;
    setModileNavBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const location = useLocation();
    const pathname = location.pathname;
    return (
        <Link key={"mobile" + link.to} to={link.to || ""} onClick={() => setModileNavBarOpen(false)}>
            <div className={`${styles.mobileLink} ${pathname === link.to ? styles.active : ""}`}>
                <link.icon className={styles.mobileLinkIcon} />
                {link.label}
            </div>
        </Link>
    );
};

type StandardNavBarProps = {
    links: Array<LinkType>;
};

const StandardNavBar = ({ links }: StandardNavBarProps) => {
    const location = useLocation();
    const pathname = location.pathname;
    const [modileNavBarOpen, setModileNavBarOpen] = useState<boolean>(false);

    return (
        <>
            <div className={styles.standardNavBar}>
                <div className={styles.left}>
                    <img className={styles.volumLogo} alt="volum" src={VolumLogo} />
                </div>
                <div className={styles.middle}>
                    {links.map((link) => {
                        if (!link.children || link.children.length === 0) {
                            return (
                                <Link key={"link-" + link.label} to={link.to || "/"}>
                                    <div className={`${styles.link} ${pathname === link.to ? styles.active : ""}`}>
                                        {link.label}
                                    </div>
                                </Link>
                            );
                        } else {
                            return (
                                <div key={"link-category-" + link.label} className={`${styles.link}`}>
                                    {link.label} <span className={styles.triangle}>▼</span>
                                    <div className={styles.dropdown}>
                                        <div className={styles.dropdownMouseCatcher} />
                                        {link.children.map((child) => (
                                            <Link
                                                key={"sublink-" + child.to}
                                                to={child.to || "/"}
                                                className={styles.dropdownLink}>
                                                <child.icon className={styles.dropdownIcon} />
                                                <div>{child.label}</div>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>
                <div className={styles.right}>
                    <AlignRightIcon className={styles.menuIcon} onClick={() => setModileNavBarOpen(true)} />
                    <Link to={"/profile"} className={styles.profileLink}>
                        <UserIcon className={styles.userIcon} />
                    </Link>
                </div>
            </div>
            {modileNavBarOpen && (
                <div className={styles.mobileNavBar}>
                    <div className={styles.mobileNavBarClose} onClick={() => setModileNavBarOpen(false)}>
                        <CancelIcon className={styles.mobileNavBarCloseIcon} />
                    </div>
                    <div>
                        {links.map((link) => {
                            if (!link.children || link.children.length === 0) {
                                return <MobileLink link={link} setModileNavBarOpen={setModileNavBarOpen} />;
                            } else {
                                return link.children.map((child) => (
                                    <MobileLink link={child} setModileNavBarOpen={setModileNavBarOpen} />
                                ));
                            }
                        })}
                        <MobileLink
                            link={{ icon: UserIcon, label: "Profil", to: "/profile" }}
                            setModileNavBarOpen={setModileNavBarOpen}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default StandardNavBar;
