import { lazy } from "react";
import { RouteType } from "./router";

const AgentsSpacesRouter = lazy(() => import("src/routes/agents/spaces/AgentsSpacesRouter"));
const MissionsContainer = lazy(() => import("src/routes/agents/demands/missions-container"));
const ManagedContainer = lazy(() => import("src/routes/agents/managed/managed-container"));
const SimulationsRouter = lazy(() => import("src/routes/agents/simulations/SimulationsRouter"));
const FinancialsRouter = lazy(() => import("src/routes/agents/financials/financials-router"));
const Remover = lazy(() => import("src/routes/agents/admin/Remover"));
const PricesEditor = lazy(() => import("src/routes/agents/admin/PricesEditor"));

/**
 * Routes specific to logged-in agents.
 */
const agentsRoutes: Array<RouteType> = [
    { path: "/spaces", component: AgentsSpacesRouter },
    { path: "/demands", component: MissionsContainer },
    { path: "/managed", component: ManagedContainer },
    { path: "/simulations", component: SimulationsRouter },
    { path: "/financials", component: FinancialsRouter },
    { path: "/admin/remover", component: Remover },
    { path: "/admin/prices-editor", component: PricesEditor },
    { path: "/", exact: true, redirect: "/spaces/offers" },
].map((route) => ({ ...route, private: true }));

export default agentsRoutes;
