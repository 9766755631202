import ApolloProvider from "./ApolloProvider";
import RecoilProvider from "./RecoilProvider";
import AuthProvider from "./AuthProvider";

type AppProviderType = { readonly children: JSX.Element };

const AppProvider = ({ children }: AppProviderType): JSX.Element => {
    return (
        <RecoilProvider>
            <ApolloProvider>
                <AuthProvider>{children}</AuthProvider>
            </ApolloProvider>
        </RecoilProvider>
    );
};

export default AppProvider;
