import environment from "src/utils/environment";

/** Do not use directly, please implement a function in events.ts */
export const _plausibleEvent = (action: string, extra: { [key: string]: string | number }): void => {
    const plausible = (window as any).plausible;

    if (environment !== "PROD") {
        console.log(`Sent plausible event: "${action}" with data:\n${JSON.stringify(extra)}`);
        return;
    }
    if (plausible === undefined) return;

    plausible(action, { props: extra });
};
